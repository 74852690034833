// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galleria-js": () => import("./../../../src/pages/galleria.js" /* webpackChunkName: "component---src-pages-galleria-js" */),
  "component---src-pages-hinnasto-js": () => import("./../../../src/pages/hinnasto.js" /* webpackChunkName: "component---src-pages-hinnasto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tuotesarjat-js": () => import("./../../../src/pages/tuotesarjat.js" /* webpackChunkName: "component---src-pages-tuotesarjat-js" */)
}

